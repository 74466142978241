import React from 'react';
import * as Marked from 'marked';

import {
  Stack,
  Nav,
  MessageBar,
  MessageBarType,
  Shimmer
} from 'office-ui-fabric-react';
import { Card } from '@uifabric/react-cards';

import articles from '../../data/supportArticles';

export default class FAQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      article: null,
      articleAnchor: null,
      loading: false
    };
  }

  getArticle = (name, articleAnchor) => {

    this.setState({ loading: true });

    fetch('/support/articles/' + name)
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }
        return res;
      })
      .then(res => res.text())
      .then(article => this.setState({ article, articleAnchor: articleAnchor.substr(1) }))
      .catch(err => this.props.showWarn('This article is unavailable at this time.'))
      .finally(() => this.setState({ loading: false }));
  }

  renderHeading = (text, level) => {

    return `
      <div>
        <span class="ms-fontSize-14 ms-fontWeight-semibold">
          ${text}
        </span>
      </div>
    `;
  }

  renderImage = (href, title, text) => {

    return `
      <img
        style="max-width: 100%; border: 1px solid #ddd;"
        src="${/^https?:/.test(href) ? href : '/support/articles/' + (href.startsWith('/') ? href.substr(1) : href)}"
        alt="${text}" />
    `;
  }

  renderText = text => {
    return `
      <p class="ms-fontSize-14 ms-fontWeight-regular">
        ${text}
      </p>
    `;
  }

  componentDidMount() {

    if (this.props.initialArticleKey) {

      const article = articles
        .map(a => a.children.find(ch => ch.key === this.props.initialArticleKey))
        .find(a => !!a);

      if (!article) {
        this.props.showWarn('This article is no longer available.')
        return;
      }

      this.getArticle(article.articleUrl, '#initial.' + article.key);
    }
  }

  render() {

    const { article, loading } = this.state;

    const renderer = new Marked.Renderer();
    renderer.heading = this.renderHeading;
    renderer.image = this.renderImage;
    renderer.paragraph = this.renderText;

    return (
      <React.Fragment>
        <Stack>
          
          <MessageBar
            messageBarType={MessageBarType.info}
          >Select an article to view support information.</MessageBar>

          <Nav
            initialSelectedKey={this.props.initialArticleKey || undefined}
            groups={articles.map(grp => ({
              name: grp.heading,
              expandAriaLabel: `Expand ${grp.heading} section`,
              collapseAriaLabel: `Collapse ${grp.heading} section`,
              links: grp.children.map(item => ({
                key: item.key,
                name: item.title,
                url: `#${grp.heading}.${item.key}`,
                onClick: (ev) => {
                  ev.preventDefault();
                  this.getArticle(item.articleUrl, `#${grp.heading}.${item.key}`);
                }
              }))
            }))} />

          {article && (
            <Card tokens={{ childrenMargin: 12 }}>
              <Card.Item>
                <div dangerouslySetInnerHTML={{ __html: Marked.parse(article, { renderer }) }}>
                </div>
              </Card.Item>
            </Card>
          )}
          {loading && !article && (
            <Stack tokens={{ childrenGap: 10 }}>
              <Shimmer width="100%" />
              <Shimmer width="90%" />
              <Shimmer width="80%" />
              <Shimmer width="100%" />
            </Stack>
          )}

        </Stack>
      </React.Fragment>
    );
  }
};
