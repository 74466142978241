import createFetchFactory from '../createFetch';
import { STRINGS } from '../data/stringtable';

export async function getSiteCollection(url) {

  // assume shared documents for now
  const matches = url.match(/https:\/\/(.+\.sharepoint\.com)(.+)/);
  const tenant = matches[1];

  // firstly, verify the tenant
  if (!tenant || tenant.indexOf('.') === -1) {
    throw new Error(STRINGS.ERRORS.INVALID_TENANT);
  }
  const tenantRes = await (createFetchFactory(`https://login.microsoftonline.com/${tenant.split('.')[0]}.onmicrosoft.com/.well-known/openid-configuration`))('GET', '/')();
  if (!tenantRes.ok) {
    throw new Error(STRINGS.ERRORS.INVALID_TENANT);
  }

  const urlpath = url.substr(('https://' + tenant).length).split('/').filter(site => site);
  const allSites = urlpath.slice(0, urlpath.length - 1)
    .map((site, i) => {
      let prev = (i > 0 ? urlpath.slice(0, i).join('/') : '');
      return (prev ? prev + '/' : '') + site;
    })
    .reverse();

  // go ahead and grab the first site available
  for (let i = 0; i < allSites.length; i++) {
    const res = await (createFetchFactory(`https://${tenant}/${allSites[i]}`))('GET', '/_api/site')();
    
    if (res.status !== 404) {
      return allSites[i];
    }
  }
  return null;
};