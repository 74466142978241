import * as React from 'react';

import * as moment from 'moment';
import { Text, Link } from 'office-ui-fabric-react';
import { getLicenseValidateUri } from '../../auth';

const styles = {
  trialRemainingContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingBottom: '1em',
    background: '#fff',
    // left: '50%',
    // transform: 'translateX(-50%)',
    width: window.innerWidth
  }
}

export default class LicenseMgr extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      license: null,
      daysUntilExpires: null
    };
  }

  componentDidMount() {

    this.props.onLoadingChange(true);

    fetch(getLicenseValidateUri() + '?tenant=' + this.props.tenant)
      .then(res => res.json())
      .then(license => {

        let daysUntilExpires = 0;
        if (license && license.expires === true && license.expiresAt) {
          daysUntilExpires = moment(license.expiresAt).diff(new Date(), 'days') + 1;
          this.setState({ daysUntilExpires });
        }

        if ((license && license.expires === false) || daysUntilExpires > 0) {
          this.props.onActivated(true);
        }

        return license;
      })
      .then(license => this.setState({ license }))
      .catch(() => this.props.onError(
        <Text>
          Error contacting licensing server. Contact
          <Link href="mailto:support@mojosoup.com.au">support@mojosoup.com.au</Link> if the issue persists.
        </Text>
      ))
      .finally(() => this.props.onLoadingChange(false));
  }

  render() {

    const { license } = this.state;

    let trialTimeRemaining = (license && license.valid && (moment(license.expiresAt).diff(new Date(), 'days') + 1)) || 0;
    if (trialTimeRemaining < 0) {
      trialTimeRemaining = 0;
    }

    return (
      <div style={styles.trialRemainingContainer}>
        <div style={{ padding: '0 .5em 0 .5em' }}>
          {license && license.expires && (
            <React.Fragment>
              <Text styles={{ root: { fontStyle: 'italic', display: 'block', paddingBottom: '.25em' } }}>
                {trialTimeRemaining > 0
                  ? `${trialTimeRemaining} day${(trialTimeRemaining !== 1 && 's') || ''} remaining on trial period.`
                  : <strong>Trial period expired. Please purchase this product.</strong>
                }
              </Text>
              <Text>Contact <Link href="mailto:sales@mojosoup.com.au">sales@mojosoup.com.au</Link> for a quote.</Text>
            </React.Fragment>
          )}
          {/* {license && license.expires === false && (
            <Text styles={{ root: { fontStyle: 'italic' } }}>Product registered to {this.props.tenant}</Text>
          )} */}
        </div>
      </div>
    );
  }
}