import urljoin from 'url-join';
import { STRINGS } from './data/stringtable';

const ACCESS_TOKEN_KEY = STRINGS.STORAGE_KEYS.ACCESS_TOKEN;
const REFRESH_TOKEN_KEY = STRINGS.STORAGE_KEYS.REFRESH_TOKEN;
const ACCESS_TOKEN_EXPIRES_KEY = STRINGS.STORAGE_KEYS.ACCESS_TOKEN_EXPIRES;

const CLIENT_IDS = {
  'localhost': '6495ed04-7b9c-4f3a-871c-cfae557527b5',
  'addinhosting.z8.web.core.windows.net': 'b96d7e6b-1502-4ce5-adc1-560afff34e3e',
  'projectonlinedocuhelper.z8.web.core.windows.net': 'd18096b8-1be5-4c9e-b733-fb5b0cc52276'
};
const REDIRECT_URIS = {
  'localhost': 'https://localhost:3000/?authorize',
  'addinhosting.z8.web.core.windows.net': 'https://addinhosting.z8.web.core.windows.net/?authorize',
  'projectonlinedocuhelper.z8.web.core.windows.net': 'https://projectonlinedocuhelper.z8.web.core.windows.net/?authorize'
};
const LICENSE_URIS = {
  'localhost': 'http://localhost:7071/api/LicenseValidate',
  'projectonlinedocuhelper.z8.web.core.windows.net': 'https://projectonlinedochelperapi.azurewebsites.net/api/LicenseValidate'
};

export function getLicenseValidateUri() {
  return LICENSE_URIS[window.location.hostname];
}

export function getRedirectUri() {
  return REDIRECT_URIS[window.location.hostname];
}

export function getAuthorizeEndpoint (site, state) {

  let client_id = CLIENT_IDS[window.location.hostname];
  const redirect_uri = REDIRECT_URIS[window.location.hostname];
  const stateUriComponent = encodeURIComponent(btoa(state));

  return urljoin(site,
    '/_layouts/oauthauthorize.aspx' + 
    '?IsDlg=1' + 
    '&client_id=' + client_id +
    '&redirect_uri=' + encodeURIComponent(redirect_uri) +
    '&response_type=code' +
    '&state=' + stateUriComponent);
}

export const getStoredToken = () => {

  if (localStorage.getItem(ACCESS_TOKEN_KEY) && localStorage.getItem(ACCESS_TOKEN_EXPIRES_KEY) && localStorage.getItem(REFRESH_TOKEN_KEY)) {

    // check we still have a valid access token
    const tokenExpires = localStorage.getItem(ACCESS_TOKEN_EXPIRES_KEY);
    
    if (tokenExpires - new Date().getTime() > 0) {

      return {
        access_token: localStorage.getItem(ACCESS_TOKEN_KEY),
        refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY)
      };
    } else {

      return {
        access_token: null,
        refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY)
      };
    }
  } else {
    return {
      access_token: null,
      refresh_token: null
    };
  }
};

export const clearStoredAccessToken = () => {

  localStorage.setItem(ACCESS_TOKEN_KEY, '');
  localStorage.setItem(ACCESS_TOKEN_EXPIRES_KEY, '');
}