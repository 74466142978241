import React from 'react';
import {
  PrimaryButton,
  DefaultButton,
  Stack,
  Spinner,
  SpinnerSize,
  Pivot,
  PivotItem,
  MessageBar,
  MessageBarType,
  Link
} from 'office-ui-fabric-react';
import Select from 'react-select'
import * as _ from 'lodash';

import formatValue, { formatFieldName, createField } from '../../lib/formats';

import { resolveEnterpriseLookups } from '../../lib/projectsvr';
import TabDetails from './tab/TabDetails';
import TooltipButton from '../TooltipButton/TooltipButton';
import FAQ from '../faq/Faq';

import { STRINGS } from '../../data/stringtable';

const styles = {
  actionButton: {
    root: {
      width: '100%'
    }
  },
  pivotTab: {
    padding: '.3em'
  }
};

const Office = window.Office;

export default class Taskpane extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      listItem: null,
      project: null,
      projectGuid: '',
      fields: [],
      projectFields: [],
      lists: [],
      selectedField: '',
      loading: false,
      loadingLabel: '',
      teachBubbleOpen: true,
      numContentControls: 0
    };

    this.selectorRef = React.createRef();

    this.insertField = this.insertField.bind(this);
    this.refreshDocument = this.refreshDocument.bind(this);
    this.clearDocumentFields = this.clearDocumentFields.bind(this);
    this.getDocument = this.getDocument.bind(this);
    this.resolveCNameValue = this.resolveCNameValue.bind(this);
    this.getNumCControls = this.getNumCControls.bind(this);
  }

  async getDocument(api, docPath, projectGuid) {

    this.setState({ loadingLabel: 'Loading document data...' });

    const odataPattern = /^odata.+$/i;

    // grab list item
    const listItemReqUri = `/_api/Web/GetFileByServerRelativePath(decodedurl='${docPath}')/ListItemAllFields`;
    let res = await api('GET', listItemReqUri)();

    if (res.status === 403 || res.status === 401) {
      throw new Error(STRINGS.ERRORS.ACCESS_DENIED_VERIFY_INSTALL);
    } else if (res.status === 400 || res.status === 500) {
      throw new Error(STRINGS.ERRORS.UNKNOWN);
    } else if (res.status === 404) {
      throw new Error('Document with path \'' + docPath + '\' not found. Please reopen the add-in.');
    }

    const listItem = await res.json();
    
    // filter out the hidden fields
    const showDocLibFieldsError = () => this.props.showWarn(STRINGS.ERRORS.RETRIEVE_DOC_LIB_FLDS);
    const editLink = listItem && listItem['odata.editLink'];
    let allowedFields = null;
    if (editLink) {
      const listWebUrlMatches = editLink.match(/(Web\/Lists.+)(?:\/.+$)/);
      if (listWebUrlMatches && listWebUrlMatches[1]) {

        // construct request to grab the actual fields
        res = await api('GET', `/_api/${listWebUrlMatches[1]}/Fields?$filter=Hidden eq false&$select=Title,EntityPropertyName`)();
        const actualFields = await res.json();

        if (actualFields && actualFields.value && Array.isArray(actualFields.value)) {

          allowedFields = actualFields.value;
        } else {
          showDocLibFieldsError();
        }
      } else {
        showDocLibFieldsError();
      }
    } else {
      showDocLibFieldsError();
    }

    // construct new list item fake object, with cleaned field names
    const cleanListItem = {
      // eslint-disable-next-line
      'Filename': (Office.context.document && Office.context.document.url && Office.context.document.url.match(/[^\/\\]+$/)[0]) || ''
    };
    allowedFields.forEach(f => {

      if (listItem[f.EntityPropertyName]) {
        cleanListItem[f.Title] = listItem[f.EntityPropertyName];
      }
    });
    const fields = _.flatten(Object.keys(cleanListItem).map(field => createField(cleanListItem, field)));

    // now grab project data
    this.setState({ loadingLabel: 'Loading project data...' });
    
    const baseProjReqUri = `/_api/ProjectServer/Projects(guid'${projectGuid}')`;
    let projectRequests = await Promise.all([
      api('GET', baseProjReqUri + '?$expand=customFields')(),
      api('GET', baseProjReqUri + '/Owner')(),
      api('GET', baseProjReqUri + '/IncludeCustomFields')()
    ]);
    let [ data, owner, project ] = await Promise.all(projectRequests.map(res => res.json()))

    // add the owner
    project = Object.assign({}, project, {
      Owner: (owner && owner.Title) || '(no owner)'
    });

    if (data && data.Id) {

      // go grab the custom fields
      const customFields = data.CustomFields;

      const projectFields = Object.keys(project)
        .filter(field => !odataPattern.test(field))
        .map(field => {

          const fallback = { key: field, label: formatFieldName(field), value: project[field] }
          const customFieldPattern = /custom(?:_?).*_([A-z0-9]+)$/i;
          if (customFieldPattern.test(field) && customFields) {

            // find the proper field name is data.CustomFields[]{ Name }
            const customField = customFields
              .find(cf => cf &&
                cf.Name &&
                customFieldPattern.test(cf.InternalName) &&
                cf.InternalName.match(customFieldPattern)[1] === field.match(customFieldPattern)[1]
              );

            if (customField) {
              return { key: field, label: formatFieldName(customField.Name), value: project[field], fieldType: customField.FieldType };
            }
          }
          return fallback;
        });

      // resolve enterprise custom fields (can't use functional high order methods w async)
      this.setState({ loadingLabel: 'Loading custom data...' });
      let lkpRequests = [];
      for (let i = 0; i < projectFields.length; i++) {

        const item = projectFields[i];
        if (item && item.key && item.value) {

          const value = project[item.key];

          // resolve lookups & re-assign values
          const lkpPattern = /entry(?:_?).*_([A-z0-9]+)$/i;
          if ((Array.isArray(value) && value.map(val => lkpPattern.test(val)).findIndex(val => val === false) === -1) || lkpPattern.test(value)) {

            const entries = Array.isArray(value) ? value : [value];
            lkpRequests = [ ...lkpRequests, resolveEnterpriseLookups(api, entries).then(entryValues => {
              if (entryValues) {
                projectFields[i].value = entryValues;
              }
            }) ];
          }
        }
      }

      if (lkpRequests.length > 0) {
        await Promise.all(lkpRequests);
      }

      this.setState({ loadingLabel: '' });

      return { listItem, project, fields, projectFields };
    } else if (!projectGuid) {

      // eslint-disable-next-line no-throw-literal
      throw (
        <React.Fragment>
          Unable to retrieve Project ID from this document's site.
          Please verify you are accessing this document from a PWA project site and you have permissions to access this project.
          <Link target="_blank" href="https://docs.microsoft.com/en-us/project/manage-connected-sharepoint-sites-in-project-server-2013">Click here to learn more.</Link>
        </React.Fragment>); //'Unable to retrieve Project ID. Please verify you are accessing this document from a PWA project site and you have permissions to access this project.');
    } else {

      let message = 'Error querying Project Server for Project ID ' + projectGuid;
      if (data['odata.error'] && data['odata.error'].message) {
        message = data['odata.error'].message.value;
      }
      throw new Error(message);
    }
  }

  async componentDidMount() {

    await this.getNumCControls();

    // grab the project GUID
    const { api } = this.props;

    let projectGuid = null;
    try {
      const res = await api('GET', '/_api/Web/AllProperties?$select=mspwaprojuid')();
      if (res.status === 403) {
        this.props.showError(STRINGS.ERRORS.ACCESS_DENIED_VERIFY_INSTALL);
        return;
      }

      const guidData = await res.json();
      projectGuid = guidData.MSPWAPROJUID;
    } catch (err) {

      this.props.showError(err);
      return;
    }
    this.setState({ projectGuid });
  }

  async insertField(controlName, value, fieldType) {

    let displayText = value;

    window.Word.run(async context => {

      const caret = context.document.getSelection();
      context.load(caret);
      await context.sync();

      const control = caret.insertText(formatValue(displayText, fieldType), 'After').insertContentControl();
      control.appearance = 'BoundingBox';
      control.title = controlName;

      context.load(control);

      try {
        await context.sync();
      } catch (err) {
        this.props.showWarn(STRINGS.ERRORS.WORD_ONLINE_NOT_SUPPORTED)
      }
    });
    await this.getNumCControls();
  }

  resolveCNameValue(key, projectFields, itemFields) {

    // grab option in list, then ret
    let srcFields = key.startsWith('Project') ? projectFields : itemFields;
    const selectedLabel = key.match(/(?:Project|Document)\.(.+)$/)[1];

    return srcFields.find(f => f.label === selectedLabel);
  }

  async refreshDocument(projectGuid, updateDocFields) {

    this.setState({ loading: true });
    
    const { api } = this.props;

    const url = (Office.context && Office.context.document && Office.context.document.url) || '';
    const docPathMatches = (url && url.match(/https:\/\/.+\.sharepoint\.com(.+)/)) || '';
    if (!docPathMatches || docPathMatches.length < 1) {
      this.props.showError(STRINGS.ERRORS.DOC_PATH_UNAVAILABLE);
      this.setState({ loading: false });
      return;
    }
    
    const docPath = docPathMatches[1];
    let newDoc;
    try {

      newDoc = await this.getDocument(api, docPath, projectGuid);

      if (!newDoc) {
        this.props.showError(STRINGS.ERRORS.ERROR_RETRIEVING_PROJ_DATA)
        return;
      }
    } catch (err) {
      this.setState({ loading: false });

      if (typeof err === 'string' || (err.props && err.props.children)) {
        this.props.showError(err);
      } else if (err && err.message) {
        this.props.showError(err.message);
      } else {
        this.props.showError(STRINGS.ERRORS.ERROR_RETRIEVING_PROJ_DATA);
      }
      return;
    }

    const { listItem, fields, projectFields, project } = newDoc;
    
    this.setState({ listItem, fields, projectFields, project });
    if (!updateDocFields) {
      this.setState({ loading: false });
      return;
    }

    await window.Word.run(async context => {
  
      // grab content controls
      let contentControls = context.document.contentControls;
      context.load(contentControls);
      await context.sync();
      
      // for each of these controls, go ahead and replace the text
      context.document.contentControls.items
        .filter(control => control.title && control.title.indexOf('.') > 0)
        .map(control => ({ field: this.resolveCNameValue(control.title, projectFields, fields), control }))
        // .filter(ctrl => ctrl.field)
        .forEach(ctrl => {
          if (ctrl.field) {
            ctrl.control.insertText(formatValue(ctrl.field.value, ctrl.field.fieldType), 'Replace');
          } else {
            ctrl.control.clear();
          }
        });

        try {
          context.load(context.document.contentControls);
          await context.sync();
        } catch (err) {
          this.props.showWarn(STRINGS.ERRORS.WORD_ONLINE_NOT_SUPPORTED);
        }
      });

    await this.getNumCControls();
    this.setState({ loading: false });
  }

  async clearDocumentFields() {

    this.setState({ loading: true });

    await window.Word.run(async context => {

      const contentControls = context.document.contentControls;
      context.load(contentControls);
      await context.sync();

      context.document.contentControls.items
        .filter(control => control.title && control.title.indexOf('.') > 0)
        .filter(control => /(project|document)$/i.test(control.title.split('.')[0]))
        .forEach(control => control.insertText(STRINGS.APP.CLEAR_FIELD_CONTENT, 'Replace'));

      try {
        context.load(context.document.contentControls);
        await context.sync();
      } catch (err) {
        this.props.showWarn(STRINGS.ERRORS.WORD_ONLINE_NOT_SUPPORTED);
      }
    });

    await this.getNumCControls();
    this.setState({ loading: false });
  }

  async getNumCControls() {

    // if we're in a doc, grab the number of content controls
    if (Office.context.document && Office.context.document.url) {

      let numControls = 0;
      await window.Word.run(async context => {

        const contentControls = context.document.contentControls;
        context.load(contentControls);
        await context.sync();
  
        if (context.document.contentControls && context.document.contentControls.items) {
  
          numControls = context.document.contentControls.items
            .filter(control => control.title && control.title.indexOf('.') > 0)
            .filter(control => /(project|document)$/i.test(control.title.split('.')[0]))
            .length;
        }
      });

      this.setState({ numContentControls: numControls });
    }
  }

  render() {

    const {
      listItem,
      project,
      fields,
      projectFields,
      projectGuid,
      numContentControls,
      loadingLabel,
      loading
    } = this.state;

    let selectedField = this.state.selectedField || null;

    const insertToDocument = (selected) => {
      if (selected && selected.label) {

        const opt = this.resolveCNameValue(selected.label, projectFields, fields);
        this.insertField(selected.label, opt.value, opt.fieldType);
      }
    }

    const selectableFields = _.orderBy([
      ...(projectFields && projectFields.map(f => ({ label: `Project.${f.label}`, value: f.label }))) || [],
      ...(fields && fields.map(f => ({ label: `Document.${f.label}`, value: f.label }))) || []
    ], 'label');

    const tabHeaderBtnStyles = {
      disabled: loading,
      style: loading ? ({ color: '#aaa' }) : undefined
    };

    const showInitialLoadSpinner = (!listItem || !project || !fields) && loading;
    const canSelectField = !(loading || !listItem || !project);

    return (
      <React.Fragment>
        <Pivot>

          <PivotItem style={styles.pivotTab} headerButtonProps={tabHeaderBtnStyles} itemIcon="FileTemplate" headerText="Template">
            <TabDetails>
              {STRINGS.APP.TABS.TEMPLATE.DETAILS}
            </TabDetails>

            <Stack tokens={{ childrenGap: 5 }} >

              {!showInitialLoadSpinner && numContentControls === 0 && !loading && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  {STRINGS.APP.TABS.TEMPLATE.NO_FIELDS_MESSAGE}&nbsp;
                  <Link onClick={() => this.getNumCControls()}>(refresh)</Link>
                </MessageBar>
              )}

              <TooltipButton
                tooltipLabel={STRINGS.APP.TABS.TEMPLATE.POPULATE_BUTTON.TOOLTIP}
                buttonComponent={PrimaryButton}
                buttonStyles={styles.actionButton}
                disabled={loading || numContentControls === 0}
                onClick={() => this.refreshDocument(projectGuid, true)}
              >{STRINGS.APP.TABS.TEMPLATE.POPULATE_BUTTON.TEXT}</TooltipButton>

              <TooltipButton
                tooltipLabel={STRINGS.APP.TABS.TEMPLATE.RESET_BUTTON.TOOLTIP}
                buttonComponent={DefaultButton}
                buttonStyles={styles.actionButton}
                disabled={loading || numContentControls === 0}
                onClick={() => this.clearDocumentFields(projectGuid)}
              >{STRINGS.APP.TABS.TEMPLATE.RESET_BUTTON.TEXT}</TooltipButton>

            </Stack>
          </PivotItem>

          <PivotItem style={styles.pivotTab} headerButtonProps={tabHeaderBtnStyles} itemIcon="PageEdit" headerText="Builder">
            <TabDetails>
              {STRINGS.APP.TABS.BUILDER.DETAILS}
            </TabDetails>

            <Stack tokens={{ childrenGap: 20 }}>

              {!canSelectField && !loading && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  {STRINGS.APP.TABS.BUILDER.LOAD_FIELDS_MESSAGE}
                </MessageBar>
              )}

              <div ref={this.selectorRef}>
                <Select
                  placeholder={STRINGS.APP.TABS.BUILDER.DROPDOWN_PLACEHOLDER}
                  isSearchable={true}
                  options={selectableFields}
                  isLoading={loading}
                  isDisabled={!canSelectField}
                  onChange={item => this.setState({ selectedField: item })} />
              </div>

              {!showInitialLoadSpinner && (
                <Stack tokens={{ childrenGap: 5 }} >

                  <TooltipButton
                    tooltipLabel={STRINGS.APP.TABS.BUILDER.INSERT_BUTTON.TOOLTIP}
                    buttonComponent={PrimaryButton}
                    buttonStyles={styles.actionButton}
                    disabled={loading || !selectedField || !canSelectField}
                    onClick={() => insertToDocument(selectedField)}
                  >{STRINGS.APP.TABS.BUILDER.INSERT_BUTTON.TEXT}</TooltipButton>

                  <TooltipButton
                    tooltipLabel={STRINGS.APP.TABS.BUILDER.RELOAD_BUTTON.TOOLTIP}
                    buttonComponent={DefaultButton}
                    buttonStyles={styles.actionButton}
                    disabled={loading}
                    onClick={() => this.refreshDocument(projectGuid, true)}
                  >{STRINGS.APP.TABS.BUILDER.RELOAD_BUTTON.TEXT}</TooltipButton>

                  <TooltipButton
                    tooltipLabel={STRINGS.APP.TABS.BUILDER.CLEAR_BUTTON.TOOLTIP}
                    buttonComponent={DefaultButton}
                    buttonStyles={styles.actionButton}
                    disabled={loading}
                    onClick={() => this.clearDocumentFields(projectGuid)}
                  >{STRINGS.APP.TABS.BUILDER.CLEAR_BUTTON.TEXT}</TooltipButton>

                </Stack>
              )}
            </Stack>
          </PivotItem>

          <PivotItem style={styles.pivotTab} headerButtonProps={tabHeaderBtnStyles} itemIcon="Unknown" headerText="Support">
            <TabDetails>
              {STRINGS.APP.TABS.SUPPORT.DETAILS}
            </TabDetails>
            <FAQ showWarn={this.props.showWarn} showError={this.props.showError} />
          </PivotItem>
        </Pivot>

        {showInitialLoadSpinner &&
          <Spinner
            style={{ marginTop: '50px' }}
            label={loadingLabel || 'Loading, please wait...'}
            ariaLive="assertive"
            labelPosition="right"
            size={SpinnerSize.medium} />
        }
      </React.Fragment>
    );
  }
}