import moment from 'moment';

import { isDate, isBool, getBoolValue } from './validate';
import CF_TYPES from '../data/customFieldTypes';

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
function patchCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function formatValue(value, fieldType) {

  const defaultValue = '(none)';
  if (!value) {
    return defaultValue;
  }

  const cfType = (fieldType && CF_TYPES.find(ty => ty.id === fieldType)) || {};

  if (Array.isArray(value)) {
    return value.join(', ');
  } else if (isBool(value)) {
    return getBoolValue(value) ? 'Yes' : 'No';
  } else if (cfType.key === 'date' || isDate(value.toString())) {

    const dt = moment.parseZone(value);
    return dt.year() === 1 ? defaultValue : dt.format('DD/MM/YYYY');
  } else if (cfType.key === '$') {
    return `$${patchCommas(Number.parseFloat(value).toFixed(2))}`;

    // eslint-disable-next-line
  } else if (Number(value) === Number(value)) {

    let result = Number(value);
    if (Number(value).toString().includes('.')) {
      result = Number.parseFloat(value).toFixed(2);
    }
    return result.toString();
  } else {
    return value.toString().trim();
  }
}

export function formatFieldName(name) {

  const hexPattern = /_x([A-F0-9]+)_/ig;

  if (hexPattern.test(name)) {

    return name.replace(hexPattern, fullMatch => {
      const hexValue = fullMatch.match(/[A-F0-9]+/i);
      if (hexValue) {
        return String.fromCharCode(Number.parseInt(hexValue, 16));
      }
      return fullMatch;
    });
  }
  return name;
}

export function objectToFields(parentFieldName, parentField) {

  return Object.keys(parentField)
    .map(field => {
      const text = `${parentFieldName}.${field}`;
      return {
        key: text,
        label: text,
        value: parentField[field]
      };
    });
  // return result;
}

export function createField(data, field) {

  if (field && data[field] && !Array.isArray(data[field]) && typeof data[field] === 'object' && Object.keys(data[field]).length > 0) {
    return objectToFields(field, data[field]);
  } else {
    return [{
      key: field,
      label: formatFieldName(field),
      value: data[field]
    }];
  }
};