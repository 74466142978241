import React from 'react';
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser';

import {
  Fabric,
  PrimaryButton,
  Spinner,
  Label,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  Text,

  Stack,
  StackItem,
  Icon,
  Link
} from 'office-ui-fabric-react';

import { getSiteCollection } from '../lib/sharepoint';
import createFetchFactory from '../createFetch';
import { AUTH_UPDATE } from '../actions';
import { getStoredToken, clearStoredAccessToken } from '../auth';

import Taskpane from './taskpane/Taskpane';
import './App.scss';
import LicenseMgr from './licensemgr/LicenseMgr';
import { STRINGS } from '../data/stringtable';

const Office = window.Office;

const urlToken = new URL(window.location.href).searchParams.get('token');
const isLoginRedirect = window.location.hash.startsWith('#/login') || new URL(window.location.href).searchParams.get('code') !== null;

const styles = {
  fabric: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 .5em 0 .5em',
    // background: '#fff'
  },
  headerText: {
    marginLeft: '10px',
  },
  headerLogo: {
    width: '65px',
    justifyContent: 'left'
  },
  integrationIconContainer: {
    fontSize: '32px',
    display: 'block',
    textAlign: 'center',
    marginBottom: '20px'
  }
}

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      siteCollection: '',
      tenant: '',
      api: null,
      authenticating: false,
      loadingSite: false,
      loadingLicense: false,
      activated: false,
      errorMessage: this.props.error || null,
      warningMessage: this.props.warn || null,
      faqOpen: false
    };

    this.authenticate = this.authenticate.bind(this);
    this.createStateContext = this.createStateContext.bind(this);
    this.showError = this.showError.bind(this);
    this.showWarn = this.showWarn.bind(this);
  }

  authenticate(siteUrl, refresh_token) {

    const ACCESS_TOKEN_KEY = 'access_token';
    const REFRESH_TOKEN_KEY = 'refresh_token';
    const ACCESS_TOKEN_EXPIRES_KEY = 'access_token_expires';

    const setTok = tok => {

      try {
        this.setState({ authenticating: false });
        this.props.dispatch({ type: AUTH_UPDATE, token: tok });
      } catch (err) {
        this.showError(err.message || err.toString());
      }
    };
    const authError = err => {

      // perhaps the user hasn't installed the sharepoint add-in?
      this.setState({ authenticating: false });
      if (err.error_description && err.error_description.includes('AADSTS500011') && err.error_description.includes('invalid_resource')) {

        window.sessionStorage.clear();

        this.showWarn((
          <React.Fragment>
            Incomplete add-in installation. Please
            <Link href="https://documenthelper.mojosoup.com.au/?splash=welcome" target="_blank">click here</Link> and
            follow the instructions to complete the installation.
          </React.Fragment>)
        );
      } else {
        this.showError(err.error_description);
      }
    };

    const { access_token } = getStoredToken();
    if (access_token) {

      setTok(access_token);
      return;
    }

    const loginLocation = new URL(window.location.href);
    loginLocation.pathname = '/';
    if (refresh_token) {
      loginLocation.searchParams.append('authorize', null);
      loginLocation.searchParams.append('state', encodeURIComponent(btoa(new URL(siteUrl).host.split('.')[0])));
      loginLocation.searchParams.append('refresh_token', encodeURIComponent(refresh_token));
    } else {
      loginLocation.hash = '#/login';
      loginLocation.searchParams.append('site', encodeURIComponent(siteUrl));
    }

    const dialogWidth = Math.round(680/*px*/ / window.outerWidth * 100);
    const dialogHeight = Math.round(322/*px*/ / window.outerHeight * 100);

    Office.context.ui.displayDialogAsync(loginLocation.href, { width: dialogWidth, height: dialogHeight, promptBeforeOpen: !!refresh_token }, result => {

      const dialog = result.value;

      // handle trust decline/dialog close
      dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
        
        this.setState({ authenticating: false });
        this.showError('Add-in authorization failed. Please trust this add-in to use the Document Helper.');
      });

      // handle when we get a message
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg) {

        dialog.close();
        if (arg.message) {

          const messageData = JSON.parse(decodeURIComponent(arg.message));

          if (messageData.error && messageData.error_description) {
            
            authError(messageData);
          } else {

            const tokenExpiresDt = new Date(new Date().getTime() + messageData.expires_in * 1000);
            localStorage.setItem(ACCESS_TOKEN_KEY, messageData.access_token);
            localStorage.setItem(ACCESS_TOKEN_EXPIRES_KEY, tokenExpiresDt.getTime());
            
            if (messageData.refresh_token) {
              localStorage.setItem(REFRESH_TOKEN_KEY, messageData.refresh_token);
            }

            setTok(messageData.access_token);
          }
        }
      });
    });
  }

  async createStateContext() {

    if (!Office.context.document && Office.context.document.url) {
      return;
    }

    // grab the site collection and tenant
    const url = Office.context.document.url;
    if (url && url.match(/https:\/\/.+\.sharepoint\.com/)) {
      
      this.setState({ loadingSite: true });
      const siteCollection = await getSiteCollection(url);
      this.setState({ loadingSite: false });

      const tenant = url.match(/https:\/\/(.+\.sharepoint\.com)(.+)/)[1];
      const api = createFetchFactory(`https://${tenant}/${siteCollection}`);

      this.setState({
        tenant,
        siteCollection, 
        api
      });
      return { api, siteCollection };
    }
    return null;
  }

  showError(err) {
    console.error(err);
    this.setState({ errorMessage: typeof err === 'string' || (err && err.props && err.props.children) ? err : JSON.stringify(err) });

    Sentry.captureException(new Error(JSON.stringify(err)));
  }

  showWarn(err) {
    console.warn(err);
    this.setState({ warningMessage: typeof err === 'string' || (err && err.props && err.props.children) ? err : JSON.stringify(err) });

    Sentry.captureException(new Error(JSON.stringify(err)));
  }

  componentDidCatch(err, info) {
    this.showError(err);
  }

  async componentDidMount() {

    const selfUrl = new URL(window.location.href);

    // check to see if we have an access token...
    if (urlToken) {

      window.Office.context.ui.messageParent(urlToken);
    } else if (selfUrl.searchParams.get('error')) {
      
      let errorMsg = selfUrl.searchParams.get('error_description') || selfUrl.searchParams.get('error') || STRINGS.ERRORS.AUTHORIZATION_FAILED;
      if (errorMsg && /^[a-z_]+$/.test(errorMsg) && errorMsg.length > 1) {
        errorMsg = errorMsg.substr(0, 1).toUpperCase() + errorMsg.replace('_', ' ').substr(1) + '.';
      }

      this.showError(errorMsg);
      window.Office.context.ui.messageParent(JSON.stringify({ error: selfUrl.searchParams.get('error'), error_description: errorMsg }));
    } else if (Office.context.document && Office.context.document.url && !this.state.api) {

      try {
        const { api, siteCollection } = await this.createStateContext();

        const { access_token, refresh_token } = getStoredToken();

        const siteUrl = new URL(Office.context.document.url);
        siteUrl.pathname = siteCollection;
        if (api && siteCollection && (access_token || refresh_token)) {

          this.setState({ authenticating: true });

          const testRes = await api('GET', '/_api/site', access_token)();
          if (testRes.status === 200) {
            this.authenticate(siteCollection);
          } else if (refresh_token) {

            this.authenticate(siteUrl.href, refresh_token);

            clearStoredAccessToken();
          }
        }
      } catch (err) {
        this.setState({ loadingSite: false });
        this.showWarn(STRINGS.ERRORS.INVALID_DOC_URL);
      }
    } else if (Office.context.document && typeof Office.context.document.url === 'string' && this.state.activated) {
      this.showWarn(STRINGS.ERRORS.INVALID_DOC_URL);
      this.setState({ loadingSite: false });
    } else if (!isLoginRedirect && !urlToken && (!Office.context.document || !Office.context.document.url)) {
      this.showWarn(STRINGS.ERRORS.INVALID_DOC_URL);
    }
  }

  render() {

    const {
      siteCollection,
      api,
      tenant,
      loadingLicense,
      errorMessage,
      warningMessage,
      activated
    } = this.state;
    const { token } = this.props.auth;

    const siteUrl = (Office.context.document && Office.context.document.url) || '';

    const httpApi = (method, endpoint) => api(method, endpoint, token);

    const triggerAuth = async () => {
      this.setState({ authenticating: true });

      // get the actual site
      const siteColPath = siteCollection || await getSiteCollection(siteUrl);
      const siteCol = new URL(siteUrl);
      siteCol.pathname = siteColPath;
      this.authenticate(siteCol.href);
    };

    return (
      <Fabric style={styles.fabric}>
        <header className="app-header">
          <img style={styles.headerLogo} src="/img/icon-80.png" alt="logo" />
          <Text styles={{ root: styles.headerText }} variant="large">Document Helper</Text>
        </header>
        {!urlToken && (!token || !activated) && siteUrl && api && !this.state.authenticating && (
          <Stack tokens={{ childrenGap: 20 }}>
            <StackItem>
              <Label styles={{ root: { textAlign: 'center' } }}>
                Integrates with
              </Label>
              <div style={styles.integrationIconContainer}>
                <Icon className="integrationIcon" styles={{ root: { color: '#038387' } }} iconName="SharepointLogoInverse" />
                <Icon className="integrationIcon" styles={{ root: { color: '#31752f' } }} iconName="ProjectLogo32" />
                <Icon className="integrationIcon" styles={{ root: { color: '#2b579a' } }} iconName="WordLogo" />
              </div>
            </StackItem>
            <StackItem>
              {/* Logos and short summary */}
              <Text block variant="medium">
                Productivity add-in to aid in creating, maintaining and populating document templates.
                Build new, maintain, edit and populate templates with data from SharePoint and Project Online.
              </Text>
            </StackItem>
            <StackItem>
              {/* usage instructions */}
              <Text block variant="medium">
                To get started, create a new Word document in a Project Online Project Site and use the add-in to
                create your document templates.
              </Text>
            </StackItem>
            {(!loadingLicense && activated && (
              <StackItem>
                <Label
                  styles={{ root: { display: !this.state.authenticating ? null : 'none' } }}
                >Please connect your project site to continue.</Label>
                <PrimaryButton
                  style={{ display: !this.state.authenticating ? null : 'none', width: '100%', marginTop: '10px' }}
                  text="Connect"
                  onClick={triggerAuth}
                />
              </StackItem>
            )) || (loadingLicense && (
              <Spinner
                style={{ display: this.state.loadingLicense ? null : 'none' }}
                label="Verifying license..."
                ariaLive="assertive"
                labelPosition="right"
                size={SpinnerSize.medium}
              />
            )) || ''}
          </Stack>
        )}
        <Stack tokens={{ childrenGap: 20 }}>
          <Spinner
            style={{ display: this.state.authenticating || this.state.loadingSite ? null : 'none' }}
            label={(this.state.loadingSite && 'Loading project site...') || (this.state.authenticating && 'Authenticating...') || 'Loading...'}
            ariaLive="assertive"
            labelPosition="right"
            size={SpinnerSize.medium}
          />
          {!urlToken && token && siteUrl && tenant && activated && api && (
            <Taskpane
              siteCollection={siteCollection}
              siteUrl={siteUrl}
              api={httpApi}
              showError={this.showError}
              showWarn={this.showWarn}
            />
          )}
          {isLoginRedirect && !errorMessage && !warningMessage && (
            <Spinner label="Please wait..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.medium} />
          )}
          {errorMessage && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              // truncated={true}
              overflowButtonAriaLabel="See more"
              onDismiss={() => this.setState({ errorMessage: null })}
              dismissButtonAriaLabel="Close"
            >
              {typeof errorMessage === 'string' ? errorMessage : errorMessage.props.children}
            </MessageBar>
          )}
          {warningMessage && (
            <MessageBar
              messageBarType={MessageBarType.severeWarning}
              isMultiline={true}
              // truncated={true}
              overflowButtonAriaLabel="See more"
              onDismiss={() => this.setState({ warningMessage: null })}
              dismissButtonAriaLabel="Close"
            >
              {typeof warningMessage === 'string' ? warningMessage : warningMessage.props.children}
            </MessageBar>
          )}
          {tenant && (
            <React.Fragment>
              {/* padding for license info */}
              <div style={{ height: '50px' }}></div> 

              <LicenseMgr
                tenant={tenant}
                onError={this.showError}
                onLoadingChange={newLoadingState => this.setState({ loadingLicense: newLoadingState })}
                onActivated={newActivationStatus => this.setState({ activated: newActivationStatus })}
              />
            </React.Fragment>
          )}
        </Stack>
      </Fabric>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(App);