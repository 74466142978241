import * as _ from 'lodash';

export async function resolveEnterpriseLookups(api, entries) {

  const lkpTablesRes = await api('GET', '/_api/ProjectServer/LookupTables?$expand=Entries&$select=Id,Entries/Id')();
  const lkpTables = await lkpTablesRes.json();

  if (!lkpTables || !lkpTables.value) {
    return entries.map(e => null);
  }

  // go ahead and parse the entry guids
  const guids = entries
    .map(entry => entry.match(/entry(?:_?).*_([A-z0-9]+)$/i))
    .filter(matches => matches.length > 0)
    .map(matches => matches[1])
    .map(guid => guid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5'));
  
  const tables = _.flatten(lkpTables.value
    .map(tbl => tbl.Entries)
    .filter(tbl => tbl && Array.isArray(tbl))
  );

  const urls = guids
    .map(guid => tables.find(e => e.Id === guid))
    .filter(entry => entry['odata.id'])
    .map(entry => entry['odata.id'])
    .map(url => url.match(/.*(\/_api.*)/))
    .filter(matches => matches.length > 0)
    .map(matches => matches[1]);
  
  let result = [];
  for (let i = 0; i < urls.length; i++) {

    const entryRes = await api('GET', urls[i])();
    const entry = await entryRes.json();
    
    if (entry.Value) {
      result = [ ...result, entry.Value ];
    }
  }

  return result;
}