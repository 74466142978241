export default [
  {
    key: 'getting-started',
    heading: 'Getting started',
    children: [
      {
        key: 'installation',
        title: 'Installation',
        articleUrl: 'installation.md'
      },
      {
        key: 'create-template',
        title: 'Creating your first template',
        articleUrl: 'create-first-template.md'
      },
      {
        key: 'populate-templates',
        title: 'Populating Templates',
        articleUrl: 'populate-template.md'
      }
    ]
  },
  {
    key: 'additional-support',
    heading: 'Additional support',
    children: [
      {
        key: 'contact-us',
        title: 'Contact Mojo Soup',
        articleUrl: 'contact-us.md'
      },
      {
        key: 'video-demos',
        title: 'Video Demos',
        articleUrl: 'video-demos.md'
      }
    ]
  }
];