import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// // IE POLYFILLS //
// import 'core-js';
// import 'core-js/es/map';
// import 'core-js/es/set';
// import 'raf/polyfill';
// //////////////////

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './serviceWorker';

import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';

import App from './components/App';
import Welcome from './components/welcome/Welcome';
import { getAuthorizeEndpoint, getRedirectUri } from './auth';

import { STRINGS } from './data/stringtable';

import './index.scss';

// initialize sentry
Sentry.init({dsn: STRINGS.CONFIG.SENTRY_DSN});

// init store & main app
const store = createStore(reducers);

function getResAuthURL() {
  return new URL({
    'localhost': 'http://localhost:7071',
    'addinhosting.z8.web.core.windows.net': 'https://uqdochelper2.azurewebsites.net',
    'projectonlinedocuhelper.z8.web.core.windows.net': 'https://projectonlinedochelperapi.azurewebsites.net'
  }[window.location.hostname]);
}

const currUrl = new URL(window.location.href);

// OAuth2 redirection logic
if (/[?&]authorize/.test(window.location) && !/error=/.test(window.location.search)) {

  const resAuthUrl = getResAuthURL();

  resAuthUrl.pathname = '/api/ResourceAuthorize';

  if (currUrl.searchParams.get('code')) {
    resAuthUrl.searchParams.append('access_code', currUrl.searchParams.get('code'));
  } else if (currUrl.searchParams.get('refresh_token')) {
    resAuthUrl.searchParams.append('refresh_token', currUrl.searchParams.get('refresh_token'));
  }
  resAuthUrl.searchParams.append('tenant', atob(decodeURIComponent(currUrl.searchParams.get('state'))));
  resAuthUrl.searchParams.append('redirect_uri', encodeURIComponent(getRedirectUri()));

  window.location.href = resAuthUrl.href;
} else if (window.location.hash.startsWith('#/login')) {

  const siteEndpoint = decodeURIComponent(currUrl.searchParams.get('site'));
  const site = new URL(siteEndpoint);

  window.location.href = getAuthorizeEndpoint(siteEndpoint, site.host.split('.')[0]);
}
initializeIcons();

// check if we're doing the splash/welcome page
if (currUrl.searchParams.get('splash')) {
  render(Welcome);
} else {

  // Bootstrap add-in
  window.Office.onReady().then(runtimeContext => {

    let error = null;
    let warn = null;
    if (runtimeContext.host && runtimeContext.host !== window.Office.HostType.Word) {
      error = STRINGS.ERRORS.ONLY_COMPATIBLE_W_WORD;
    }

    render(App, { error, warn });
  });
}

function render(Component, props = {}) {
  ReactDOM.render(
    <Provider store={store}>
      <Component {...props} />
    </Provider>, document.getElementById('root'));
}

serviceWorker.unregister();
