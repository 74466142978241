import React from 'react';
import { TooltipHost } from 'office-ui-fabric-react';

export default function TooltipButton(props) {

  const {
    buttonComponent: Btn,
    tooltipLabel,
    buttonStyles,
    disabled,
    onClick
  } = props;

  return (
    <TooltipHost content={tooltipLabel} hidden={disabled}>
      <Btn
        text={props.children}
        styles={buttonStyles}
        disabled={disabled}
        onClick={onClick} />
    </TooltipHost>
  );
}