export default function createFetchFactory(apiBaseUrl) {

  return function createFetch(method, endpoint, token) {
    const url = apiBaseUrl + endpoint;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    if (token) {
      headers['Authorization'] = token.indexOf('Bearer ') > -1 ? token : `Bearer ${token}`;
    }

    return (body) => fetch(url, { headers, method, body });
  }
}