import React from 'react';
import { Text } from 'office-ui-fabric-react';

const styles = {
  container: {
    padding: '5px 0 30px 0'
  }
}

export default function TabDetails(props) {

  return (
    <div style={styles.container}>
      <Text variant="medium">{props.children}</Text>
    </div>
  )
}