import * as ActionTypes from './actions';
import {
  combineReducers
} from 'redux';

export function userAuth(state = {
  token: '',
  client: null
}, action) {

  switch (action.type) {

    case ActionTypes.AUTH_UPDATE:

      return Object.assign({}, state, {
        token: action.token,
        // client: Client.initWithMiddleware({ authProvider: authProvider })
      });

    default:
      return state;
  }
}

export default combineReducers({
  auth: userAuth
});