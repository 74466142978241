import React from 'react';
import {
  PrimaryButton,
  Stack,
  Fabric,
  Text,
  StackItem,
  Link,
} from 'office-ui-fabric-react';

import { STRINGS } from '../../data/stringtable';

import './Welcome.scss';

export default (props) => (
  <Fabric>

    <div className="container">
      
      <Stack gap="20">
        <Text block variant="xxLarge">Welcome to the Document Helper Dashboard!</Text>
        <StackItem>
          <Text block variant="mediumPlus">
            To continue, please read our <Link href={STRINGS.URLS.PRIVACY_POLICY} target="_blank">privacy policy</Link>, then download the SharePoint Online app package below.
          </Text>
          <Text block variant="mediumPlus">
            Install the downloadable file below to the SharePoint Online tenant App Catalogue.
          </Text>
        </StackItem>

        <div style={{ marginTop: '50px' }}>
          <a href="/DHAddin.app" target="_blank" download>
            <PrimaryButton
              text="Download App Package"
              iconProps={{ iconName: 'Download' }} />
          </a>
        </div>
      </Stack>
    </div>

  </Fabric>
);