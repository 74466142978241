// https://www.w3.org/TR/NOTE-datetimes
const DATE_PATTERN = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

export function isDate(value) {

  return DATE_PATTERN.test(value);
}

export function isBool(value) {

  return typeof value === 'boolean' ||
    (typeof value === 'string' && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false'));
}

export function getBoolValue(value) {

  if (typeof value === 'boolean') {
    return value;
  } else {
    return value.toLowerCase() === 'true';
  }
}