export const STRINGS = {
  ERRORS: {
    UNKNOWN: 'Unexpected error occurred. Please reopen the add-in and try again.',
    ACCESS_DENIED_VERIFY_INSTALL: 'Access denied. Verify add-in installation - contact your system administrator.',

    RETRIEVE_DOC_LIB_FLDS: 'Unable to retrieve document library field list',
    ERROR_RETRIEVING_PROJ_DATA: 'Failed to retrieve project data.',
    INVALID_TENANT: 'Invalid tenant. Please open a document from a valid SharePoint Online site.',

    ONLY_COMPATIBLE_W_WORD: 'This add-in is only compatible with Word',
    WORD_ONLINE_NOT_SUPPORTED: 'Word Online is not supported. Please open in desktop client.',
    INVALID_DOC_URL: 'Please open a document from a valid Project Online project site.',

    AUTHORIZATION_FAILED: 'Authorization failed. Please try again.',
    DOC_PATH_UNAVAILABLE: 'Unable to retrieve document path.'
  },
  CONFIG: {
    SENTRY_DSN: 'https://48dd8fadb5bc42fda960b1a699577fe0@sentry.io/1857784'
  },
  STORAGE_KEYS: {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    ACCESS_TOKEN_EXPIRES: 'access_token_expires'
  },
  URLS: {
    PRIVACY_POLICY: 'https://mojosoup.com.au/privacy-policy-for-document-helper-word-add-in'
  },
  APP: {
    CLEAR_FIELD_CONTENT: '(template)',
    TABS: {
      TEMPLATE: {
        DETAILS: 'Update an existing template.',
        NO_FIELDS_MESSAGE: 'No fields detected in document. Use the \'Builder\' tab to create a template',
        POPULATE_BUTTON: {
          TEXT: 'Populate Document',
          TOOLTIP: 'Populate fields in document'
        },
        RESET_BUTTON: {
          TEXT: 'Reset Template',
          TOOLTIP: 'Replace fields in document with placeholders'
        }
      },
      BUILDER: {
        DETAILS: 'Create a new project document template.',
        LOAD_FIELDS_MESSAGE: 'Click \'Reload\' to get started creating a template.',
        DROPDOWN_PLACEHOLDER: 'Search for, or select a field',
        INSERT_BUTTON: {
          TEXT: 'Insert',
          TOOLTIP: 'Insert a new field into the document'
        },
        RELOAD_BUTTON: {
          TEXT: 'Reload',
          TOOLTIP: 'Update all document field data'
        },
        CLEAR_BUTTON: {
          TEXT: 'Clear all',
          TOOLTIP: 'Replace fields in document with placeholders'
        }
      },
      SUPPORT: {
        DETAILS: 'Get help and support.'
      }
    }
  }
};